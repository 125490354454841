h1.title {
  position: absolute;

  width: 356px;
  height: 70px;
  left: 537px;
  top: 172px;

  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  line-height: 70px;

  color: #04B000;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

h2.subtitle {
  position: absolute;
  width: 570px;
  height: 36px;
  left: 424px;
  top: 251px;

  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;

  text-align: center;

  color: #000000;
}

.claimed {
  position: absolute;

  width: 513px;
  height: 18px;
  right: 32px;
  top: 32px;

  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height, or 120% */

  text-align: right;

  color: #56677D;
}

span.s1 {
  position: absolute;

  width: 513px;
  height: 54px;
  left: 481px;
  top: 415px;

  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  /* or 120% */


  text-align: center;

  color: #000000;
}

span.s2 {
  position: absolute;

  width: 513px;
  height: 54px;
  left: 481px;
  top: 455px;

  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  /* or 120% */

  text-align: center;

  color: #04B000;
}

header {
  input {
    position: absolute;

    width: 402px;
    height: 58px;
    left: 447px;
    top: 326px;
    padding: 20px;

    background: #F1FFF1;
    border-radius: 100px;
  }

  button {
    position: absolute;

    width: 183px;
    height: 58px;
    left: 858px;
    top: 326px;

    background: #04B000;
    border-radius: 100px;
    color: #FFFFFF;font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
  }
}

div.footer {
  background: #F1FFF1;
  position: absolute;
  left: 0px;
  top: 600px;
  height: 900px;
  width: 100%;

  .container {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
    margin-bottom: 80px;
  }

  h3 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 26px;
    color: #000000;
  }

  p {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
  }

  .brand {
    color: #04B000;
    font-weight: bold;
  }
}
